body {
    background-color: white;
}

.headerStyle {
    position: fixed;
    display: flex;
    background-color: white;
    width: 100%;
    padding-left: 30px;
}

.titleStyle {
    padding-right: 120px;
}

.thumbsButtonsStyle {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 19px;
    padding-right: 15px;
    display: flex;
}

.contentStyle {
    padding-right: 15px;
    padding-bottom: 55px;
    padding-left: 30px;
}

.defaultButtonsStyle {
    position: fixed;
    bottom: 0;
    display: flex;
    gap: 10px;
    background-color: white;
    width:100%;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 30px;
}

